.App {
    text-align: center;
    padding: 20px;
  }
  
  h1 {
    color: #333;
  }
  
  h2 {
    color: #555;
  }
  
  video {
    margin-top: 20px;
  }
  
  a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #0070ba;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  a:hover {
    background-color: #005fa3;
  }
  